import { MDBBadgeComponent } from './mdb-badge.component';
import { NgModule } from '@angular/core';


@NgModule({
  declarations: [MDBBadgeComponent],
  exports: [MDBBadgeComponent]
})

export class BadgeModule {

}
